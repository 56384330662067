import { create } from "zustand"
import { ValidationErrors } from "../types"

export const Page = {
  SUCCESS: "success",
  FAILURE: "failure",
  DISCLOSURE: "disclosure",
  AUTHENTICATE: "authenticate",
  BASIC_AUTH: "basicAuth",
  AUTHENTICATED: "authenticated",
  TWO_FACTOR: "twoFactor",
  WHATS_APP_AUTH: "whatsAppAuth",
} as const

export type PageT = (typeof Page)[keyof typeof Page]

interface PageRouterState {
  page: PageT
  setPage: (newPage: PageT) => void
  errors: ValidationErrors
  setErrors: (errors: ValidationErrors) => void
}

export const usePageRouterStore = create<PageRouterState>((set) => ({
  page: Page.DISCLOSURE,
  setPage: (newPage: PageT) => set({ page: newPage }),
  errors: [],
  setErrors: (errors: ValidationErrors) => set({ errors }),
}))
