import { useEffect } from "react"
import { useLinkStore } from "../../stores/linkStore"
import { useLinkValidation } from "../useLinkValidation/useLinkValidation"

export const useMessageHandler = () => {
	const { setLinkToken, linkData } = useLinkStore()
	const { verifyLinkToken } = useLinkValidation()

	useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			const data = event.data
			if (data.messageType === "SEND_INFO_TO_IFRAME" && !linkData) {
				const linkToken = data.linkToken
				setLinkToken(linkToken)
				verifyLinkToken(linkToken)
			}
		}

		window.addEventListener("message", handleMessage)
		return () => window.removeEventListener("message", handleMessage)

		// eslint-disable-next-line
	}, [])
}
