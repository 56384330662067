import { useEffect } from "react"
import { useLinkStore } from "../../stores/linkStore"
import { useLiveSessionStore } from "../../stores/liveSessionStore"
import { usePageRouterStore } from "../../stores/pageRouterStore"

export const useLiveSessionAuth = () => {
	const { setPage } = usePageRouterStore()
	const { linkData, linkToken, setPublicToken } = useLinkStore()
	const { errors, setErrors } = usePageRouterStore()
	const { setLoading, setUrl, setEventSource, eventSource, establishConnection, loading, url } = useLiveSessionStore()

	useEffect(() => {
		if (!establishConnection || !linkToken || eventSource) {
			return
		}

		const eS = new EventSource(`${process.env.REACT_APP_API_URL}/v1/link/connect/${linkToken}`)

		eS.onopen = () => {
			setEventSource(eS)
		}

		eS.onmessage = (event) => {
			const data = JSON.parse(event.data)
			if (data.message === "pageChange") {
				setLoading(false)
				setUrl(data.url)
			} else if (data.message === "error") {
				setErrors([...errors, { detail: data.error, problem_type: "authentication_error" }])
				setPage("failure")
			} else if (data.message === "authenticating") {
				setLoading(true)
			} else if (data.message === "authenticated") {
				const publicToken = data.publicToken
				setPublicToken(publicToken)
				setPage("success")
			}
		}

		eS.onerror = async (err) => {
			console.error(err)
			eS.close()
			setEventSource(null)
			setErrors([...errors, { detail: "Failed to connect to Keet", problem_type: "connection_error" }])
			setPage("failure")
		}

		window.addEventListener("message", (event) => {
			if (event.data === "browserbase-disconnected") {
				setLoading(true)
			}
		})

		return () => {
			eS.close()
			window.removeEventListener("message", (event) => {})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [linkData?.id, linkToken, setErrors, setPage, setPublicToken, establishConnection])

	const LiveSession = loading ? (
		<div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="animate-spin"
			>
				<path d="M21 12a9 9 0 1 1-6.219-8.56" />
			</svg>
		</div>
	) : (
		<iframe
			title="authentication portal"
			src={`${url}&navbar=false`}
			sandbox="allow-same-origin allow-scripts"
			allow="clipboard-read; clipboard-write"
			className={`w-full h-full rounded-b-2xl`}
		/>
	)

	return { LiveSession }
}
