import { useEffect } from "react"
import { useLiveSessionAuth } from "./hooks/useLiveSessionAuth/useLiveSessionAuth"
import { sendLinkFlowReady, sendLinkTokenValid } from "./messages"
import AuthenticatedPage from "./pages/AuthenticatedPage/AuthenticatedPage"
import AuthenticatePage from "./pages/AuthenticatePage/AuthenticatePage"
import DisclosurePage from "./pages/DisclosurePage/DisclosurePage"
import FailurePage from "./pages/FailurePage/FailurePage"
import SuccessPage from "./pages/SuccessPage/SuccessPage"
import TwoFactorPage from "./pages/TwoFactorPage/TwoFactorPage"
import UsernamePasswordAuthPage from "./pages/UsernamePasswordAuthPage/UsernamePasswordAuthPage"
import WhatsAppAuthPage from "./pages/WhatsAppAuthPage/WhatsAppAuthPage"
import { useLinkStore } from "./stores/linkStore"
import { useLiveSessionStore } from "./stores/liveSessionStore"
import { Page, usePageRouterStore } from "./stores/pageRouterStore"

function App() {
  const { page, setPage } = usePageRouterStore()
  const { linkData, setLinkData, setLinkToken } = useLinkStore()
  const { setEstablishConnection } = useLiveSessionStore()
  useLiveSessionAuth()

  const verifyLinkToken = (linkToken: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/v1/link/token/validate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ linkToken }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "ok") {
          setLinkData(data.linkData)
          if (data.linkData && !data.linkData.basicAuth) {
            setEstablishConnection(true)
          }
        }
        if (data.isConnected) {
          setPage("authenticated")
        }
      })
  }

  const handleMessage = (event: MessageEvent) => {
    // Ensure the message is coming from a trusted source
    const data = event.data

    if (data.messageType === "SEND_INFO_TO_IFRAME") {
      const linkToken = data.linkToken
      setLinkToken(linkToken)
      verifyLinkToken(linkToken)
    }
  }

  useEffect(() => {
    if (linkData) {
      sendLinkTokenValid()
    }
  }, [linkData])

  useEffect(() => {
    sendLinkFlowReady()
    window.addEventListener("message", handleMessage)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {linkData ? (
        <div
          className={`w-[400px] h-[500px]
          bg-white absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] rounded-2xl
            shadow-lg z-50 flex flex-col  overflow-hidden border border-grey-200`}
        >
          {(() => {
            switch (page) {
              case Page.DISCLOSURE:
                return <DisclosurePage />
              case Page.FAILURE:
                return <FailurePage />
              case Page.SUCCESS:
                return <SuccessPage />
              case Page.AUTHENTICATE:
                return <AuthenticatePage />
              case Page.AUTHENTICATED:
                return <AuthenticatedPage />
              case Page.BASIC_AUTH:
                return <UsernamePasswordAuthPage />
              case Page.TWO_FACTOR:
                return <TwoFactorPage />
              case Page.WHATS_APP_AUTH:
                return <WhatsAppAuthPage />
              default:
                return <FailurePage />
            }
          })()}
        </div>
      ) : (
        <></>
      )}
      <div className="bg-black/60 w-screen h-screen absolute top-0 z-40 backdrop-blur-lg" />
    </>
  )
}

export default App
