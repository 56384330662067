import { create } from "zustand"

interface LiveSessionState {
	url: string | undefined
	setUrl: (sessionUrl: string | undefined) => void
	loading: boolean
	setLoading: (loading: boolean) => void
	eventSource: EventSource | null
	setEventSource: (source: EventSource | null) => void
	closeEventSource: () => void
	establishConnection: boolean
	setEstablishConnection: (establish: boolean) => void
}

export const useLiveSessionStore = create<LiveSessionState>((set, get) => ({
	url: undefined,
	setLoading: (loading: boolean) => set({ loading }),
	setUrl: (url: string | undefined) => set({ url }),
	loading: true,
	eventSource: null,
	establishConnection: false,
	setEventSource: (source) => set({ eventSource: source }),
	setEstablishConnection: (establish) => set({ establishConnection: establish }),
	closeEventSource: () => {
		set({ establishConnection: false, loading: true, url: undefined, eventSource: null })
		const { eventSource } = get()
		if (eventSource) {
			eventSource.close()
		}
	},
}))
