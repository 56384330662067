/// <reference types="react-scripts" />

import { LuLink } from "react-icons/lu"

import { GoArrowSwitch } from "react-icons/go"
import { LiaCircle } from "react-icons/lia"
import { LuShield } from "react-icons/lu"
import { Button } from "../../components/Button/Button"
import NavigationBar from "../../components/Navigation/NavigationBar"

import { useLinkStore } from "../../stores/linkStore"
import { Page, usePageRouterStore } from "../../stores/pageRouterStore"

const Header = () => {
	const { linkData } = useLinkStore()
	return (
		<div className="">
			<NavigationBar />
			<div className="w-full flex flex-row justify-center items-center gap-4 mt-1">
				{linkData?.companyLogoUri ? (
					<img
						src={linkData?.companyLogoUri}
						className="h-9 animate-in slide-in-from-right duration-500"
						alt="company logo"
					/>
				) : (
					<div className="w-9 h-9 bg-gradient-to-br from-grey-25 to-grey-100 shadow-insetSm rounded-md border border-grey-200 flex flex-col items-center justify-center animate-in slide-in-from-right duration-500">
						<LiaCircle />
					</div>
				)}
				<GoArrowSwitch className="fill-grey-400" />
				<img
					src={linkData?.integrationLogoUri}
					className="h-9 animate-in slide-in-from-left duration-500"
					alt="integration logo"
				/>
			</div>
			<div className="mt-6">
				<h1 className="text-xl font-medium bg-gradient-to-b from-primary-700 to-primary-900 text-center leading-[1.25] text-transparent bg-clip-text">
					Connect {linkData?.companyName} to {linkData?.integration}
				</h1>
				<p className="text-[0.8rem] text-grey-700 text-center mt-2 text-balance leading-relaxed">
					{linkData?.companyName} uses Keet to securely integrate with your {linkData?.integration} account and perform
					actions on your behalf.
				</p>
			</div>
		</div>
	)
}

const DisclosureItem = ({
	Icon,
	text,
	description,
}: {
	Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	text: string
	description: string
}) => {
	return (
		<li className="flex flex-row items-start gap-3 w-full">
			<Icon className="stroke-primary-700 w-[1rem] h-[1rem] stroke-[2.5px]" />
			<div>
				<p className="shrink w-full text-sm text-primary-900 leading-none">{text}</p>
				<p className="text-xs mt-1.5 text-primary-600">{description}</p>
			</div>
		</li>
	)
}

const Content = () => {
	const { linkData } = useLinkStore()
	return (
		<div className="grow h-full flex flex-col justify-center">
			<ul className="flex flex-col gap-8 h-fit  rounded-xl mx-auto px-4 py-6 border border-grey-100">
				<DisclosureItem
					Icon={LuLink}
					text={`${linkData?.companyName} will have access to your account.`}
					description="Acme will be able to complete actions on your behalf and extract data."
				/>
				<DisclosureItem
					Icon={LuShield}
					text={`Your authentication info will not be stored.`}
					description="Acme will not have access to your credentials used to access your account."
				/>
			</ul>
		</div>

		// <div className="grow px-6 flex flex-col justify-center bg-grey-50 rounded-lg w-85 mx-auto">
		//   <ul className="flex flex-col justify-between h-[8rem]">

		//     {/* <DisclosureItem Icon={LuTrash} text="You can revoke access at any time." /> */}
		//   </ul>
		// </div>
	)
}

export default function DisclosurePage() {
	const { setPage } = usePageRouterStore()
	const { linkData } = useLinkStore()

	const handleContinue = () => {
		const basicAuth = linkData?.basicAuth
		const whatsApp = linkData?.integration === "WhatsApp"

		if (basicAuth) {
			setPage(Page.BASIC_AUTH)
		} else if (whatsApp) {
			setPage(Page.WHATS_APP_AUTH)
		} else {
			setPage(Page.AUTHENTICATE)
		}
	}

	return (
		<div className="fade-in flex flex-col h-full relative px-4 py-4">
			<Header />
			<Content />

			<div className="mt-auto">
				<Button variant="primary" size="lg" width="full" onClick={handleContinue}>
					Continue
				</Button>

				<div className="mt-4">
					<p className="text-center text-primary-200 text-sm font-medium">
						Powered by{" "}
						<a
							href="https://trykeet.com"
							className="hover:text-primary-700 transition-colors transition-duration-200"
							target="_blank"
							rel="noreferrer"
						>
							Keet
						</a>
					</p>
				</div>
			</div>
		</div>
	)
}
