export async function validateLinkToken(token: string) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/link/token/validate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ linkToken: token }),
  })
  const data = await response.json()
  return data
}
