import { useEffect } from "react"
import { Overlay } from "./components/Overlay/Overlay"
import { PageWrapper } from "./components/PageWrapper/PageWrapper"
import { useLiveSessionAuth } from "./hooks/useLiveSessionAuth/useLiveSessionAuth"
import { useMessageHandler } from "./hooks/useMessageHandler/useMessageHandler"
import { sendLinkFlowReady } from "./messages"
import AuthenticatePage from "./pages/AuthenticatePage/AuthenticatePage"
import AuthenticatedPage from "./pages/AuthenticatedPage/AuthenticatedPage"
import DisclosurePage from "./pages/DisclosurePage/DisclosurePage"
import FailurePage from "./pages/FailurePage/FailurePage"
import SuccessPage from "./pages/SuccessPage/SuccessPage"
import TwoFactorPage from "./pages/TwoFactorPage/TwoFactorPage"
import UsernamePasswordAuthPage from "./pages/UsernamePasswordAuthPage/UsernamePasswordAuthPage"
import WhatsAppAuthPage from "./pages/WhatsAppAuthPage/WhatsAppAuthPage"
import { useLinkStore } from "./stores/linkStore"
import { Page, usePageRouterStore } from "./stores/pageRouterStore"

const PageRouter = () => {
  const { page } = usePageRouterStore()

  const pageComponents = {
    [Page.DISCLOSURE]: DisclosurePage,
    [Page.FAILURE]: FailurePage,
    [Page.SUCCESS]: SuccessPage,
    [Page.AUTHENTICATE]: AuthenticatePage,
    [Page.AUTHENTICATED]: AuthenticatedPage,
    [Page.BASIC_AUTH]: UsernamePasswordAuthPage,
    [Page.TWO_FACTOR]: TwoFactorPage,
    [Page.WHATS_APP_AUTH]: WhatsAppAuthPage,
  }

  const Component = pageComponents[page] || FailurePage
  return <Component />
}

function App() {
  const { linkData } = useLinkStore()
  useLiveSessionAuth()
  useMessageHandler()

  useEffect(() => {
    sendLinkFlowReady()
  }, [])

  if (!linkData) return <Overlay />

  return (
    <>
      <PageWrapper>
        <PageRouter />
      </PageWrapper>
      <Overlay />
    </>
  )
}

export default App
