import { useEffect, useState } from "react"
import QRCode from "react-qr-code"
import NavigationBar from "../../components/Navigation/NavigationBar"
import { useLinkStore } from "../../stores/linkStore"
import { usePageRouterStore } from "../../stores/pageRouterStore"

export default function WhatsAppAuthPage() {
  const { linkData, linkToken, setPublicToken } = useLinkStore()
  const { errors, setErrors } = usePageRouterStore()
  const { setPage } = usePageRouterStore()
  const [qrCode, setQrCode] = useState<string | undefined>()

  useEffect(() => {
    const eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/v1/link/connect/${linkToken}`)

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)

      if (data.message === "qrCode") {
        setQrCode(data.qrCode)
      } else if (data.message === "authenticated") {
        setPublicToken(data.publicToken)
        setPage("success")
      }
    }

    eventSource.onerror = (err) => {
      console.error(err)
      setErrors([...errors, { detail: "Failed to connect to Keet", problem_type: "connection_error" }])
      setPage("failure")
      eventSource.close()
    }

    return () => {
      eventSource.close()
      window.removeEventListener("message", (event) => {})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkData?.id, linkToken, setErrors, setPage, setPublicToken])

  return (
    <div className="fade-in h-full flex flex-col px-6 py-6">
      <NavigationBar backPage="disclosure" />

      <div className="mt-6">
        <img
          src={linkData?.integrationLogoUri}
          className="h-9 animate-in slide-in-from-top duration-500"
          alt="integration logo"
        />
        <h1 className="text-lg font-medium mt-4">Scan QR Code</h1>
        <p className="text-grey-500 text-sm">
          Go to the WhatsApp app {">"} Settings {">"} Linked Devices {">"} Link Device and scan the QR code. More
          information at this{" "}
          <a
            href="https://faq.whatsapp.com/1317564962315842/?locale=en_US&cms_platform=web"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 no-underline hover:no-underline"
          >
            link
          </a>
          .
        </p>
      </div>

      <div className="mt-6 h-[25rem]">{qrCode && <QRCode value={qrCode} />}</div>
    </div>
  )
}
